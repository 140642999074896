// Survey.js
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  RadioGroup,
  FormControl,
  FormControlLabel,
  styled,
} from "@mui/material";
import PostSurveyView from "./PostSurvey";

const StyledContainer = styled(Container)({
  padding: 0,
  textAlign: "center",
  width: "325px",
  transition: "transform 0.5s ease",
});

const QuestionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 0,
});

const OptionButton = styled(Button)(({ theme, selected }) => ({
  marginTop: 10,
  marginBottom: 5,
  width: "350px",
  height: 45,
  padding: theme.spacing(2),
  fontFamily: "'neue_hans_kendrickregular'",
  textAlign: "center",
  borderRadius: 20,
  border: `1px solid grey`,
  backgroundColor: selected ? "black" : "inherit",
  color: selected ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: selected ? "black" : theme.palette.action.hover,
  },
  marginLeft: "27px",
}));

const ButtonsContainer = styled("div")({
  marginTop: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
});

const NextButton = styled(Button)({
  width: "165px",
  height: 45,
  margin: "8px",
  borderRadius: 20,
  color: "black",
  border: "1px solid black",
  fontFamily: "'neue_hans_kendrickregular'",
});

const Survey = ({ onComplete }) => {
  const [showPostSurveyView, setShowPostSurveyView] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("prompt")) {
      localStorage.clear();
    }
  }, []);

  const initialFormData = {
    steps: [
      {
        question: "How does your skin feel during the day?",
        options: [
          { label: "DRY AND ROUGH TO TOUCH", value: "dry and rough on touch" },
          { label: "TIGHT AND LOOKS DULL", value: "tight and looks dull" },
          {
            label: "OILY, WITH NOTICABLE PORES",
            value: "oily, with noticable pores",
          },
          {
            label: "SENSITIVE AND UNCOMFORTABLE",
            value: "sensitive and uncomfortable",
          },
          {
            label: "IM HAPPY WITH HOW IT FEELS",
            value: "Im happy with how it feels",
          },
        ],
      },
      {
        question: "What is your skin goal?",
        options: [
          {
            label: "SMOOTH, FIRM AND LIFTED",
            value: "smooth, firm and lifted",
          },
          {
            label: "SOFT, NOURISHED AND RADIANT",
            value: "soft, nourished and radiant",
          },
          {
            label: "BALANCED, MATIFIED AND REFINED",
            value: "balanced, matified and refined",
          },
          {
            label: "CALM AND COMFORTABLE",
            value: "calm, comfortable and soothed",
          },
          {
            label: "VISIBLY EVEN AND SMOOTH",
            value: "visibly even and smooth",
          },
        ],
      },
      {
        question: "How do you like your skincare to feel?",
        options: [
          { label: "NOURISHING", value: "nourishing" },
          { label: "LIGHTWEIGHT", value: "lighweight" },
        ],
      },
    ],
  };

  const { control, handleSubmit } = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [swipeDirection, setSwipeDirection] = useState(null);

  const onSubmit = (data) => {
    setShowPostSurveyView(true);
    localStorage.setItem(
      "prompt",
      `question: How does your skin feel during the day? answer:${answers[0].selectedOption}; 
      question: What is your skin goal? answer: ${answers[1].selectedOption}; 
      question: How do you like your skincare to feel? answer: ${answers[2].selectedOption}`
    );
  };

  const handleOptionSelect = (selectedOption) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentStep] = { selectedOption };
      return newAnswers;
    });
  };

  const handleNext = () => {
    if (answers[currentStep]?.selectedOption) {
      setSwipeDirection("left");
      setTimeout(() => {
        setSwipeDirection("right");
        setCurrentStep((prevStep) =>
          prevStep === initialFormData.steps.length - 1 ? 0 : prevStep + 1
        );
        setTimeout(() => {
          setSwipeDirection(null);
        }, 0);
      }, 500);
    }
  };

  const handlePrev = () => {
    setSwipeDirection("right");
    setTimeout(() => {
      setSwipeDirection("left");
      setCurrentStep((prevStep) =>
        prevStep === 0 ? initialFormData.steps.length - 1 : prevStep - 1
      );
      setTimeout(() => {
        setSwipeDirection(null);
      }, 0);
    }, 500);
  };

  const handleCancel = () => {
    setShowPostSurveyView(false);
  };

  return (
    <StyledContainer
      maxWidth="sm"
      style={{
        transform: `translateX(${
          swipeDirection === "left"
            ? "200%"
            : swipeDirection === "right"
            ? "-200%"
            : "0"
        })`,
        fontFamily: "'neue_hans_kendrickregular'",
      }}
    >
      {showPostSurveyView ? (
        <PostSurveyView onCancel={handleCancel} onComplete={onComplete} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <QuestionContainer>
            <p
              style={{
                fontSize: "30px",
                marginBottom: 20,
                fontFamily: "'neue_hans_kendrickregular'",
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              {initialFormData.steps[currentStep].question}
            </p>
            <FormControl component="fieldset">
              <RadioGroup
                control={control}
                name={`steps[${currentStep}].selectedOption`}
                value={answers[currentStep]?.selectedOption || ""}
                onChange={(e) => handleOptionSelect(e.target.value)}
              >
                {initialFormData.steps[currentStep].options.map(
                  (option, optionIndex) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <OptionButton
                          selected={
                            option.value ===
                            answers[currentStep]?.selectedOption
                          }
                          onClick={() => handleOptionSelect(option.value)}
                        >
                          {option.label}
                        </OptionButton>
                      }
                      label={null}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
            <ButtonsContainer>
              <NextButton
                variant="outlined"
                onClick={handlePrev}
                disabled={currentStep === 0}
                sx={{
                  "&:hover": {
                    border: "1px solid grey",
                  },
                }}
              >
                Back
              </NextButton>

              {currentStep < initialFormData.steps.length - 1 && (
                <NextButton
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                    color: "white",
                    "&.Mui-disabled": {
                      background: "#909090",
                      color: "#D8DFEC",
                    },
                    border: "none",
                    fontFamily: "'neue_hans_kendrickregular'",
                  }}
                  onClick={handleNext}
                  disabled={!answers[currentStep]?.selectedOption}
                >
                  Next
                </NextButton>
              )}
              {currentStep === initialFormData.steps.length - 1 && (
                <NextButton
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "black",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                    color: "white",
                    "&.Mui-disabled": {
                      background: "#909090",
                      color: "#D8DFEC",
                    },
                    border: "none",
                    fontFamily: "'neue_hans_kendrickregular'",
                  }}
                  disabled={!answers[currentStep]?.selectedOption}
                >
                  Complete
                </NextButton>
              )}
            </ButtonsContainer>
          </QuestionContainer>
        </form>
      )}
    </StyledContainer>
  );
};

export default Survey;
