import React, { useState, useEffect } from "react";
import "./index.css";
const topScanner = require("../../assets/topScanner.png");
const bottomScanner = require("../../assets/bottomScanner.png");

interface IScanAnimation {
  url: string;
  foreheadLineY: number;
  chinLineY: number;
  scanTopPosition: any;
  scanMidPosition: any;
  scanBottomPosition: any;
}

const ScanAnimation: React.FC<IScanAnimation> = ({
  url,
  foreheadLineY,
  chinLineY,
  scanTopPosition,
  scanMidPosition,
  scanBottomPosition,
}) => {
  console.log(
    url,
    foreheadLineY,
    chinLineY,
    scanTopPosition,
    scanMidPosition,
    scanBottomPosition
  );
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const image = new Image();
  image.src = url;
  const halfWidth = window.innerWidth / 2;

  const [linePosition, setLinePosition] = useState(scanTopPosition);
  const [direction, setDirection] = useState(1);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const updateLinePosition = () => {
      if (direction === 1) {
        setLinePosition(chinLineY - 150);
        setDirection(-1);
      } else {
        setLinePosition(scanTopPosition);
        setDirection(1);
      }
    };

    const interval = setInterval(updateLinePosition, 2000);
    return () => clearInterval(interval);
  }, [direction, foreheadLineY, chinLineY]);

  useEffect(() => {
    const topScannerImg = new Image();
    topScannerImg.src = topScanner;
    topScannerImg.onload = () => {
      const bottomScannerImg = new Image();
      bottomScannerImg.src = bottomScanner;
      bottomScannerImg.onload = () => {
        setImagesLoaded(true);
      };
    };
  }, []);

  useEffect(() => {
    if (imagesLoaded) {
      const animationTimeout = setTimeout(() => {
        setShowAnimation(true);
      }, 500);

      return () => clearTimeout(animationTimeout);
    }
  }, [imagesLoaded]);

  return (
    <>
      {imagesLoaded && (
        <>
          <div
            className={`scan-line ${showAnimation ? "show" : ""}`}
            style={
              {
                top: `${linePosition}%`,
                width: 200,
                left: halfWidth - 100,
                transition: "opacity 1s ease-in-out",
                "--scan-top-position": `${scanTopPosition}%`,
                "--scan-mid-position": `${scanMidPosition}%`,
                "--scan-bottom-position": `${scanBottomPosition}%`,
              } as React.CSSProperties
            }
          />
        </>
      )}
    </>
  );
};

export default ScanAnimation;
