export const mapProducts = (
  products: any,
  answers: any,
  scores: any,
  productIds: any
) => {

  const getProductById = (productId: number) => {
    return products.find((product: any) => product.id === productId);
  };
  let product1;


  const wrinkles = scores[0].score;
  const firmness = scores[1].score;
  const darkSpots = scores[3].score;

  if (darkSpots) {
    product1 = {
      id: 152,
      image_link:
        "https://avant-skincare.com/744-large_default/blue-volcanic-stone-purifying-antioxydising-cleansing-gel-669203935306.jpg",
      title: "Blue Volcanic Stone Purifying & Antioxidising Cleansing Gel",
      description:
        "Awarded Women & Home Clever Skincare Awards 2022 - Best Cleanser For Oily Skin our mild, non-drying, facial cleanser, aims to effortlessly eliminate excess oil, dirt and make up without leaving skin feeling over-exposed. With a cocktail of antibacterial, anti-inflammatory and antioxidant extracts such Salicylic acid and Vitamin E, the Blue Volcanic Stone Purifying & Antioxidising Cleansing Gel helps prevent breakouts and soothe irritation. The perfect first step in your skincare regime.",
      price: 77,
      link: "https://avant-skincare.com/en/toners-cleansers/152-blue-volcanic-stone-purifying-antioxydising-cleansing-gel-669203935306-5060762540256.html",
    };
  } else {
    product1 = getProductById(165);
  }

  let product2;

  if (
    answers.includes("smooth, firm and lifted") ||
    (wrinkles > 71 && firmness > 71 && darkSpots > 71)
  ) {
    product2 = getProductById(176);
  } else {
    product2 = getProductById(379);
  }
  //>>>>>>>>>>>>>>>>>>>>>>>>>
  const product3 = getProductById(productIds[0]);
  const product4 = getProductById(productIds[1]);
  const product5 = getProductById(productIds[2]);
  const product6 = {
    id: 463,
    image_link:
      "https://avant-skincare.com/2102-large_default/avant-glow-starter-set.jpg",
    title: "Avant Glow Starter Set",
    description: `Discover Avant's Glow Starter Set and enjoy a £40 gift card, the perfect choice for newcomers or those seeking a fresh skincare experience. This set includes five of Avant’s award-winning and bestselling products in try out sizes, delivering instant hydration for a healthy, radiant complexion. Explore Avant’s 5-Star collection to enhance your skincare routine day and night, and indulge in immediate revival for glowing, smoother, plumper looking skin. `,
    price: 40,
    link: "https://avant-skincare.com/en/discovery-routine/463-avant-glow-starter-set.html",
  };

  return [
    { category: "Cleanse", product: product1 },
    { category: "Tone", product: product2 },
    { category: "Serum / SPF", product: product3 },
    { category: "Moisturise", product: product4 },
    { category: "Prevent", product: product5 },
    { category: "Starter Glow Set", product: product6 },
  ];
};
